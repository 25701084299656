// eslint-disable-next-line no-redeclare,no-unused-vars
function checkWhere(row, where, param = {}) {
  var success = false;
  if (!param.noConvert) {
    where = Object.assign({}, where);
    where = convertWhere(where, param);
  }
  $.each(where, function (key, value) {
    if (key == 'converted') return;
    success = checkWhereValue(param.object, row, key, value, param.nullAsEmpty);
    if (isset(success) && !success) return false;
  });
  return success;
}
